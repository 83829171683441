.CompareInputFilesSection {
  display: grid;
  justify-items: center;
  grid-template-rows: 1fr 1fr;
  row-gap: 40px;

  .correct-text {
    color:forestgreen;
  }

  .column {
    border: 1px solid black;
    box-sizing: border-box;
    padding: 3rem;
  }

}